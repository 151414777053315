import './tools/polyfill'

import classNames from 'classnames'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import * as Sentry from '@sentry/browser'

import { LoadPage } from './components/ePlaceHolder'
import UserConfig from './config/web-config'
import ErrorBoundary from './containers/ErrorBoundary'
import { AppContext } from './context/app'
import { setTopics } from './store/topics/actions'
import {
  getCookie,
  deleteCookie,
  displayImmediateUpdate,
  isPaywallActive,
} from './tools/tools'
import { messageErrorLoad } from './tools/newversion'
import './system/button/button.css'
import Modal from './system/modal'
import { App as AppConfig } from './tools/config'
import NativeAPI, { sendPostMessage } from './tools/nativeApi'

const ModalUpdate = Loadable({
  loader: () => import('./components/eModal/modalUpdate'),
  loading: () => null,
})

const Home = () => <div />

const Notice = Loadable({
  loader: () => import(/* webpackChunkName: "Notice" */ './pages/notice'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const Story = Loadable({
  loader: () => import(/* webpackChunkName: "Story" */ './pages/story'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const Blog = Loadable({
  loader: () => import(/* webpackChunkName: "Blog" */ './pages/blog'),
  loading: ({ error }) => {
    if (error) messageErrorLoad()
    return <LoadPage />
  },
})

const NoMatch = Loadable({
  loader: () => import(/* webpackChunkName: "NoMatch" */ './pages/nomatch'),
  loading: () => null,
})

const CustomRoute = ({ zIndex, component: PageComponent, ...rest }) => (
  <section className="route-section" style={{ zIndex }}>
    <Route {...rest} component={PageComponent} />
  </section>
)

const PAYWALL_ACTIVE = isPaywallActive()

const getUserProfile = () => {
  try {
    const userCookie = getCookie('eeecappuser')
    if (userCookie) {
      const value = JSON.parse(userCookie)
      if (!value.id) return null
      Sentry.configureScope(scope => {
        scope.setUser({ id: value.id, email: value.email })
      })
      return {
        email: value.email,
        uuid: value.id,
        emailVerified: true,
      }
    }

    const userLocalStorage = localStorage.getItem('ArcId.USER_PROFILE')
    if (!userLocalStorage) return null
    const data = JSON.parse(userLocalStorage)
    if (!data) return null
    deleteCookie('arc_e_id', '/')
    localStorage.removeItem('topicsState')
    localStorage.removeItem('userHaveSubscription')
    sendPostMessage({ type: 'auth.SIGN_OUT' })
    return null
  } catch (err) {
    Sentry.captureException(err)
    return null
  }
}

interface Props {
  config: any
  dispatch: any
  history: any
  location: any
}

interface State {
  appState: any
}
class App extends Component<Props, State> {
  togglePaywallStatus: (status: boolean) => void
  signOut: () => void
  signIn: (profile: any, fromListener?: boolean) => void
  setNightMode: (mode: boolean) => void

  constructor(props) {
    super(props)
    /* GET USER PROFILE */
    const dataUser = getUserProfile()

    this.signOut = () => {
      localStorage.removeItem('userHaveSubscription')
      this.setState(prevState => ({
        appState: {
          ...prevState.appState,
          profile: null,
          paywallStatus: false,
        },
      }))
    }

    this.signIn = (profile, fromListener) => {
      if (!fromListener) {
        NativeAPI.loadSession(localStorage.getItem('ArcId.USER_INFO'))
      }
      this.setState(prevState => ({
        appState: {
          ...prevState.appState,
          ...profile,
        },
      }))
    }

    this.togglePaywallStatus = status => {
      if (status) {
        localStorage.setItem('userHaveSubscription', 'si')
      } else {
        localStorage.removeItem('userHaveSubscription')
      }
      this.setState(prevState => ({
        appState: {
          ...prevState.appState,
          paywallStatus: status,
        },
      }))
    }

    this.setNightMode = mode => {
      this.setState(prevState => ({
        ...prevState,
        appState: {
          ...prevState.appState,
          nightMode: mode,
        },
      }))
    }

    this.state = {
      appState: {
        nightMode: UserConfig.get('mode'),
        signOut: this.signOut,
        signIn: this.signIn,
        profile: dataUser,
        paywallStatus: Boolean(localStorage.getItem('userHaveSubscription')),
        togglePaywallStatus: this.togglePaywallStatus,
        setNightMode: this.setNightMode,
      },
    }
  }

  componentDidMount() {
    const {
      config: { name },
      dispatch,
      history,
    } = this.props
    const { appState } = this.state

    /* SENTRY */
    const prod = /pwa\.(depor|elcomercio|trome|gestion)\.(pe|com)/.test(
      window.location.hostname,
    )
    Sentry.init({
      dsn: 'https://aa2c63eadc6b4402a38b2f796653323f@sentry.ec.pe/15',
      environment: prod ? 'production' : 'development',
      release: `${process.env.REACT_APP_VERSION}.${window.PACKAGE?.version}`,
      debug: !prod,
      whitelistUrls: [
        /pwa.(dev.)?(elcomercio|depor|gestion|trome).(pe|com)\/static\/js/,
      ],
      blacklistUrls: [/s0\.2mdn\.net/, /platform\.twitter\.com/],
      // ignoreErrors: ['ServiceWorker'],
      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb?.data?.url?.includes('stats.g.doubleclick.net')) {
          return null
        }
        return breadcrumb
      },
      beforeSend(event, hint = {}) {
        if (process.env.REACT_APP_ENVIRONMENT === 'development') {
          console.log(event, hint)
        }
        const evt = event
        const error = hint.originalException as Error
        if (error?.message && evt.tags) {
          const match = error.message.match(/^\[(.*?)\]/i)
          if (match) {
            const [, categoryError] = match
            evt.tags.category = categoryError
          }
        }
        return evt
      },
    })
    Sentry.configureScope(scope => {
      scope.setTag('brand', name)
      scope.setTag('category', 'GENERAL')
    })

    if (appState.profile) {
      this.signIn({ profile: appState.profile }, true)
    }

    const IMMEDIATE_UPDATE = displayImmediateUpdate()
    if (IMMEDIATE_UPDATE) {
      Modal.open({
        content: () => <ModalUpdate />,
        myClass: 'is-modal-center is-modal-swh',
        animation: 'centerFade',
        disableBack: true,
      })
    }

    /* Postmessage from React Native */
    window.addEventListener(
      'message',
      event => {
        const { content, type, payload } = event.data
        if (type === 'auth.PROFILE') {
          const profile = {
            email: payload.email,
            uuid: payload.id,
            emailVerified: true,
          }
          this.setState(prevState => ({
            ...prevState,
            appState: {
              ...prevState.appState,
              profile,
            },
          }))
          const noticeWrapper = document.querySelector('.wrap-view-new')
          if (noticeWrapper) noticeWrapper.removeAttribute('style')
          window.currentModal?.modal?.remove()
          // Remove ArcId
          localStorage.removeItem('ArcId.USER_INFO')
          localStorage.removeItem('ArcId.USER_PROFILE')
          // Hide Relogin Notification
          document.querySelector('.notification-relogin')?.classList.add('out')
          Sentry.configureScope(scope => {
            scope.setUser({ id: payload.id, email: payload.email })
          })
          return
        }
        if (type === 'auth.LOGOUT') {
          this.signOut()
        }
        if (type === 'subscription.UPDATE_STATUS') {
          this.togglePaywallStatus(payload.status)
          return
        }
        if (type !== 'pushHistory') return
        try {
          const url = new URL(content)
          history.push({
            pathname: url.pathname,
            search: '?ref=openapp',
          })
        } catch (error) {
          console.error(error, content)
        }
      },
      false,
    )

    window.currentModal = {
      modal: null,
      onClose: user => {
        this.signIn(
          {
            profile: user,
          },
          true,
        )
      },
    }

    const payload: { auth?: Record<string, string> } = {}
    if (appState.profile?.uuid) payload.auth = appState.profile
    sendPostMessage({ type: 'init.WEB_LOADED', payload })
    window.appHistory = this.props.history

    const initialData = {
      isSubscriptor: appState.paywallStatus,
      paywallActive: PAYWALL_ACTIVE,
      pages: [],
    }

    NativeAPI.loadInitialData(initialData)

    window.NATIVE_CONNECTION = {
      favorites: {
        setIds: () => {},
        setStories: () => {},
      },
      mode: { setNightMode: this.setNightMode },
      topics: { setTopics: (topics: string[]) => dispatch(setTopics(topics)) },
    }
  }

  render() {
    const {
      config: {
        marketing,
        name,
      },
      location,
    } = this.props
    const { appState } = this.state
    const myKey = location.pathname.split('/')[1] || 'category'

    const classes = classNames(name, {
      'night-mode': appState.nightMode,
      [`zoom-${UserConfig.get('fontSize')}`]: true,
    })

    return (
      <div>
        <Helmet>
          <meta name="theme-color" content={marketing.firstColor} />
          <link id="link-canonical" rel="canonical" href={marketing.url} />
          <link rel="shortcut icon" href={`/brands/${name}/favicon.png`} />
          <link rel="preload" as="image" href={`/brands/${name}/logo.svg`} />
          <link
            rel="preload"
            as="image"
            href={`/brands/${name}/placeholder.svg`}
          />
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          {marketing.font && <link href={marketing.font} rel="stylesheet" />}
          {name === 'elcomercio' && (
            <link href={`/brands/elcomercio/styles.css`} rel="stylesheet" />
          )}
          <title>{marketing.brand}</title>
          <style type="text/css">
            {`
            .splash-screen, .is-header.is-primary, .e-head.bg-brand {
              background-color: ${marketing.firstColor};
            }
            .page-view {
              height: 100vh;
            }
            @supports (padding: env(safe-area-inset-bottom)) {
              .page-view {
                height: 100vh;
              }
            }
          `}
          </style>
          <body className={classes} />
        </Helmet>
        <AppContext.Provider value={appState}>
          <TransitionGroup className="transition-group">
            <CSSTransition
              key={myKey}
              timeout={{ enter: 300, exit: 300 }}
              classNames="fade"
            >
              <Switch location={location}>
                <Route exact path="/" component={Home} />
                {AppConfig.key === 'gestion' ? (
                  <CustomRoute
                    path={'/blog'}
                    component={Blog}
                    zIndex={4}
                  />
                ) : null}
                <CustomRoute
                  path={'/news/:id'}
                  component={Notice}
                  zIndex={4}
                />
                <CustomRoute
                  path={'/story/:storyId'}
                  component={Story}
                  zIndex={4}
                />
                <Route component={NoMatch} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </AppContext.Provider>
      </div>
    )
  }
}

const AppWrapper: React.FC<any> = props => {
  return (
    <div>
      <ErrorBoundary fallback={<>Error 500</>}>
        <App {...props} />
      </ErrorBoundary>
    </div>
  )
}

const mapStateToProps = state => ({
  config: state.configBrand,
})

export default withRouter(connect(mapStateToProps)(AppWrapper))
