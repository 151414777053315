import React from 'react'

interface AppContextInterface {
  nightMode?: boolean
  profile?: null | {
    attributes?: any
    contacts?: any[]
    uuid: string
    email: string
    emailVerified: boolean
    firstName?: string
    lastName?: string
    identities?: any
    accountReference?: any
  }
  paywallStatus?: boolean
  sign?: boolean
  signOut?: any
  signIn?: any
  togglePaywallStatus?: any
  updateFollow?: (entity: string) => (body: any) => void
  updateNotifications?: (entity: string) => (body: any) => Promise<void>
  setNightMode: (mode: boolean) => void
}

const initialContext = {}

export const AppContext = React.createContext<AppContextInterface>(
  (initialContext as unknown) as AppContextInterface,
)
