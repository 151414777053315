const defaultProps = {
  card: {
    layout: 'magazine',
    header: false,
  },
  template: [],
}

export default {
  name: 'trome',
  url: ['pwa.trome.pe', 'pwa.dev.trome.pe'],
  main: '/category/portada',
  marketing: {
    url: 'https://trome.com/',
    domain: 'trome.com',
    brand: 'Trome',
    firstColor: '#FF8400',
    secondaryColor: '#EF5B25',
    font:
      'https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@400;500;600;900&display=swap',
    secondaryFont:
      'https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;800;900&display=swap',
  },
  components: {},
  ads: {
    home: [],
  },
  jwplayers: {
    gec: { player: 'mw2yaIG8', playerAds: 'TxIvz082' },
  },
  defaultProps,
}
