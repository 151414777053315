const defaultProps = {
  card: {
    layout: 'magazine',
    description: true,
  },
  template: [],
}

export default {
  name: 'gestion',
  url: ['pwa.gestion.pe', 'pwa.dev.gestion.pe'],
  main: '/',
  marketing: {
    url: 'https://gestion.pe/',
    domain: 'gestion.pe',
    brand: 'Gestión',
    firstColor: '#F5E9DE',
    secondaryColor: '#841b24',
    font:
      'https://fonts.googleapis.com/css2?family=Petrona:wght@300;500;700&family=IBM+Plex+Sans:wght@500;600;700&display=swap',
  },
  templates: [
    {
      _id: 'portada',
      default: { card: defaultProps.card },
      path: '/',
      template: defaultProps.template,
    },
  ],
  internalPages: {
    terminos:
      'https://ecoid.pe/terminos_y_condiciones/108f85a3d8e750a325ced951af6cd758a90e73a34',
    politicas:
      'https://ecoid.pe/politica_privacidad/108f85a3d8e750a325ced951af6cd758a90e73a34',
  },
  ads: {
    home: [],
  },
  jwplayers: {
    elcomercio: { player: 'VWAez0JC', playerAds: 'diFqwoLd' },
    gec: { player: '5yA2N7Qh', playerAds: 'LIi3eTVR' },
  },
  suscription: {
    signwallCount: 3,
    paywallCount: 8,
  },
  defaultProps,
}
