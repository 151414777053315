const defaultProps = {
  card: {
    layout: 'magazine',
    description: true,
  },
  template: [],
}

export default {
  name: 'depor',
  url: ['pwa.depor.com', 'pwa.dev.depor.com'],
  main: '/category/portada',
  marketing: {
    url: 'https://depor.com/',
    domain: 'depor.com',
    brand: 'Depor',
    firstColor: '#007c31',
    secondaryColor: '#f25a23',
    font:
      'https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap',
  },
  ads: {
    home: [],
  },
  jwplayers: {
    gec: { player: 'nM3HvVdY', playerAds: 'Hm6R42Pa' },
  },
  defaultProps,
}
