const brands = ['elcomercio', 'gestion', 'depor', 'trome']
const defaultBrand = 'elcomercio'

export const initializeConfig = (hostname: string) => async dispatch => {
  const [, domain] = hostname.match(/(\w+)\.\w+$/) || []
  const brand = brands.indexOf(domain) >= 0 ? domain : defaultBrand

  const { default: config } = await require(`./../../config/brand/${brand}`)
  return dispatch({
    type: 'LOADING_CONFIG',
    config,
  })
}
