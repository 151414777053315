const defaultProps = {
  card: {
    layout: 'magazine',
    description: true,
    author: true,
  },
  template: [],
}

export default {
  name: 'elcomercio',
  url: ['pwa.elcomercio.pe', 'pwa.dev.elcomercio.pe'],
  main: '/category/portada',
  marketing: {
    url: 'https://elcomercio.pe/',
    domain: 'elcomercio.pe',
    brand: 'El Comercio',
    firstColor: '#FFCB05',
    secondaryColor: '#FFCB05',
  },
  internalPages: {
    terminos:
      'https://ecoid.pe/terminos_y_condiciones/a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
    politicas:
      'https://ecoid.pe/politica_privacidad/a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
  },
  ads: {
    home: [],
  },
  jwplayers: {
    gec: { player: 'QesGNLbA', playerAds: 'oxSCGgVV' },
    elcomercio: { player: 'VWAez0JC', playerAds: 'diFqwoLd' },
  },
  suscription: {
    signwallCount: 4,
    paywallCount: 9,
  },
  defaultProps,
}
