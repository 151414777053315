declare global {
  interface Window {
    ReactNativeWebView?: {
      postMessage(message: string): void
    }
  }
}

type InitialData = {
  pages?: { id: string; path: string; name: string; iconName: string }[]
  paywallActive?: boolean
  isSubscriptor?: boolean
}

type RegularPostMessage = {
  type: string
  content?: {
    [prop: string]: unknown
  }
  payload?: {
    [prop: string]: unknown
  }
}

const version = window.PACKAGE?.version || 0

export function sendPostMessage(data: RegularPostMessage = { type: '' }) {
  if (process.env.NODE_ENV === 'development') console.log(data)
  window.ReactNativeWebView?.postMessage?.(JSON.stringify(data))
}

class NativeAPI {
  loadInitialData(initialData: InitialData) {
    const { paywallActive, isSubscriptor, pages } = initialData

    sendPostMessage({
      type: 'app.LOAD_CONFIG',
      payload: {
        pages,
        isSubscriptor,
        paywallActive,
      },
    })
  }

  loadSession(userInfo) {
    sendPostMessage({
      type: 'auth.SIGN_IN',
      payload: {
        userInfo: version > 408 ? JSON.parse(userInfo) : userInfo,
      },
    })
  }

  navigate(screenName: string, screenParams?: Record<string, any>) {
    sendPostMessage({
      type: 'navigation.NAVIGATE_TO_SCREEN',
      payload: { name: screenName, params: screenParams },
    })
  }

  navigateGoBack() {
    sendPostMessage({ type: 'navigation.GO_BACK' })
  }

  subscribeToTopic(topic: string) {
    sendPostMessage({
      type: 'topics.SUBSCRIBE_TO_TOPIC',
      payload: { topic },
    })
  }

  unsubscribeFromTopic(topic: string) {
    sendPostMessage({
      type: 'topics.UNSUBSCRIBE_FROM_TOPIC',
      payload: { topic },
    })
  }
}

export default new NativeAPI()
